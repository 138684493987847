<script setup>
// import { ref } from 'vue';
import {Head} from '@inertiajs/vue3';
// import ApplicationMark from '@/Components/ApplicationMark.vue';
import Banner from '@/Components/Banner.vue';
import Sidebar from "@/Components/Sidebar.vue";
import Footer from "@/Components/Footer.vue";
import ReferralModal from "@/Components/ReferralModal.vue";
import {applyTheme} from "@/helper.js";
// import Sidebar from "@/Components/SidebarOld.vue";
// import Dropdown from '@/Components/Dropdown.vue';
// import DropdownLink from '@/Components/DropdownLink.vue';
// import NavLink from '@/Components/NavLink.vue';
// import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';

defineProps({
    title: String,
});

// const showingNavigationDropdown = ref(false);
//
// const switchToTeam = (team) => {
//     router.put(route('current-team.update'), {
//         team_id: team.id,
//     }, {
//         preserveState: false,
//     });
// };

// const logout = () => {
//     router.post(route('logout'));
// };

applyTheme()

</script>

<template>
    <div class="h-screen flex flex-col justify-between bg-gray-100">
        <Sidebar/>
        <Head :title="title"/>
        <Banner/>
        <div class="dark:bg-deep-blue overflow-y-auto grow flex flex-col" scroll-region>
            <div class="grow">
                <!-- Page Heading -->
                <header v-if="$slots.header" class="bg-white dark:bg-dark-blue shadow">
                    <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <slot name="header"/>
                    </div>
                </header>
                <!-- Page Content -->
                <main>
                    <div
                        class="max-w-7xl sm:max-w-2xl md:max-w-3xl lg:max-w-7xl mx-auto py-2 px-2.5 sm:px-6 lg:px-8 md:py-6">
                        <slot/>
                    </div>
                </main>
            </div>
            <Footer class="grow-0"/>
        </div>
        <ReferralModal/>
    </div>
</template>
